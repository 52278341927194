import React, {useState} from "react"
import {Link, navigate} from "gatsby";
import {useMutation} from "@apollo/client";
import Cookies from "js-cookie";
import {gql} from "@apollo/client/core";
import {useTranslation} from "react-i18next";
import logo from "../images/logo.png";
import dancingVideo from "../videos/dancing.mp4";

const REGISTRATION_MUTATION = gql`
mutation($username: String!, $email: String!, $password: String!) {
  register(input: { username: $username, email: $email, password: $password }) {
    jwt
  }
}`

export default function Register() {
    const { t } = useTranslation()
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [
        login,
        { loading: mutationLoading, error: mutationError },
    ] = useMutation(REGISTRATION_MUTATION)

    const handleRegister = async (event) => {
        // Block native form submission.
        event.preventDefault()

        login({
            variables: {
                username: email.split('@')[0],
                email: email,
                password: password
            },
        }).then((res) => {
            Cookies.set("_CA", res.data.register.jwt, { expires: 1, secure: true, sameSite: 'strict' })
            navigate("/tenant/new")
        })
    }

    if (mutationLoading) {
        console.log(mutationLoading)
    }

    if (mutationError != undefined) {
        console.log(mutationError)
    }

    return (
        <div className="min-h-screen bg-white flex">
            <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <img
                            className="h-12 w-auto"
                            src={logo}
                            alt="ClickAnd"
                        />
                        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                            {t('signup.title')}
                        </h2>
                        <p className="mt-2 text-sm text-gray-600">
                            {t('signup.subtitle.or')}{' '}
                            <Link to="/" className="font-medium text-indigo-600 hover:text-indigo-500">
                                {t('signup.subtitle')}
                            </Link>
                        </p>
                    </div>

                    <div className="mt-8">

                        <div className="mt-6">
                            <form
                                onSubmit={handleRegister}
                                className="space-y-6">
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        {t('input.email')}
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            required
                                            onChange={(e) => {
                                                setEmail(e.target.value)
                                            }}
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>

                                <div className="space-y-1">
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        {t('input.password')}
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="password"
                                            name="password"
                                            type="password"
                                            autoComplete="current-password"
                                            required
                                            onChange={(e) => {
                                                setPassword(e.target.value)
                                            }}
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>

                                <div className="space-y-0.5">
                                    <div className="flex items-center">
                                        <input
                                            id="privacy_policy"
                                            name="privacy_policy"
                                            type="checkbox"
                                            required
                                            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                        />
                                        <label htmlFor="privacy_policy" className="ml-2 block text-sm text-gray-900">
                                            {t('signup.privacy')}
                                        </label>
                                    </div>
                                </div>

                                <div className="space-y-0.5">
                                    <div className="flex items-center">
                                        <input
                                            id="terms_condition"
                                            name="terms_condition"
                                            type="checkbox"
                                            required
                                            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                        />
                                        <label htmlFor="terms_condition" className="ml-2 block text-sm text-gray-900">
                                            {t('signup.terms')}
                                        </label>
                                    </div>
                                </div>

                                <div>
                                    <button
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        {t('signup.button')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1">
                <video
                    className="absolute inset-0 h-full w-full object-cover"
                    autoPlay
                    loop
                    playsInline
                    muted>
                    <source
                        src={dancingVideo}
                        type="video/mp4" />
                </video>
            </div>
        </div>
    )
}
